import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import Img from "gatsby-image"
import SEO from "../components/seo"

const IndexPage = props => (
  <Layout>
    <SEO title="Home" />
    <div className="container">
      <div className="header">
        <div className="header-left">
          {props.pageContext.qr && <img src={props.pageContext.qr} />}
          {props.pageContext.filterLink && (
            <>
              <h2 className="android-header">ANDROID LINK</h2>
              <p>
                <a href={props.pageContext.filterLink}>
                  Click here to access filter with an Android device.
                </a>
              </p>
            </>
          )}
          {!props.pageContext.qr && (
            <h1>
              Check back on 11/26 to access the exclusive Giving Tuesday AR
              Filter!
            </h1>
          )}
        </div>
        <div className="header-right">
          <Image />
        </div>
      </div>

      <div className="content">
        <div className="content-left">
          <video controls>
            <source src="/HowTo_Video_112519_V04.webm" type="video/webm" />
            <source src="/HowTo_Video_112519_V04.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="content-right">
          <h2>EXCLUSIVE FILTER HOW-TO STEPS</h2>
          <ol>
            <li>
              iOS : OPEN CAMERA APP on your phone Android: Open QR Scanner on
              your phone or use this link and skip to step 3
            </li>
            <li>SCAN QR Code</li>
            <li>
              A Drop Down Menu will appear, select “OPEN IN {`<browser name>`}”
              ***make sure your browser isn’t in private mode***
            </li>
            <li>
              You will be taken to a WEBPAGE where you will LOGIN to your
              Facebook account
            </li>
            <li>From there it will take you to our AR FILTER page</li>
            <li>
              <div>Two Options will appear</div>
              <ul>
                <li>
                  if you have the Facebook App on your phone, select OPEN
                  FACEBOOK CAMERA
                </li>
                <li>
                  If you don’t have the Facebook App on your phone, select Get
                  the Facebook App.
                </li>
              </ul>
            </li>
            <li>
              Finally select OPEN IN FACEBOOK and your Facebook Camera will load
              the Filter
            </li>
            <li>
              RECORD YOUR VIDEO! Remember to raise your eyebrows, open your
              mouth, and be expressive to get the most out the filter!
            </li>
            <li>
              <b>SAVE YOUR VIDEO TO YOUR CAMERA ROLL.</b>
            </li>
            <li>
              <div>SHARE IT</div>
              <ul>
                <li>
                  Upload to your Facebook Stories - please make sure to ADD THE
                  FACEBOOK FUNDRAISING STICKER to promote the fundraiser of your
                  choice.
                </li>
                <li>
                  Finally SEND US YOUR VIDEO your video{" "}
                  <b>(without the sticker from stories)</b> from your camera
                  roll - upload it privately{" "}
                  <a href="https://www.dropbox.com/request/Jzzx0rKn11T72eINiDae">
                    here
                  </a>
                  .
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
